<template>
  <div id="educationCard">
    <div class="course-path-card mb-2 cursor-pointer"
      @click="bottomTitle !== 'İzlenemez' ? $emit('onClickCard', id) : null">
      <img :src="image" alt="">
      <div class="course-path-card-contents">
        <div class="course-card-info d-flex justify-content-between">
          <div class="d-flex align-items-center justify-content-between w-100">
            <span class="catagroy">{{ subTitle }}</span>
            <span class="order d-block" v-if="repeatable == 1">{{ orderNumber }}. Tekrar</span>
          </div>
          <div class="ml-auto" v-if="gift">
            <i class="icon-feather-gift" uk-tooltip="title: Hediyeli Eğitim; pos: bottom-center" title=""
              aria-expanded="false"></i>
          </div>
          <div>
            <i class="icon-feather-gift" v-if="hasPresent" uk-tooltip="title: Hediyeli Eğitim; pos: bottom-center"></i>
          </div>
        </div>
        <h3 class="assignment-name"> {{ title }} </h3>
        <!--<a @click="$emit('onClickCard', id)" type="button" class="btn btn-sm cursor-pointer" :class="bottomClass" >
          <i :class="iconClass"></i> {{bottomTitle}}
        </a>-->
        <a :class="bottomClass" v-if="!isAssignmentEndDateExpired" class="btn btn-success btn-sm text-white" type="button">
          <i :class="iconClass"></i> {{ bottomTitle }}
        </a>

        <a :class="bottomClass" v-if="isAssignmentEndDateExpired" class="btn btn-secondary btn-sm text-secondary" type="button" @click.stop
          uk-tooltip="title: Eğitim bitiş tarihi geçmiştir.; pos: bottom-center">
          <i :class="iconClass"></i> {{ bottomTitle }}
        </a>

        <div class="course-progressbar mt-2" :uk-tooltip="'title:%' +
          processWidth +
          ' ' +
          $t('education.Completed') +
          '; pos: top-center'
          ">
          <div class="course-progressbar-filler" :class="processClass" :style="{ width: processWidth + '%' }"></div>
        </div>
        <div class="mt-2">
          <p class="date d-flex align-items-center"><i class="icon-feather-calendar mr-1"></i>{{ time }}</p>
        </div>
      </div>
      <div class="course-path-card-footer">
        <div class="star-rating" v-if="rate">
          <span class="avg"> {{ rate }} </span>
          <span class="star"></span>
          <span class="star"></span>
          <span class="star"></span>
          <span class="star"></span>
          <span class="star"></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'educationCard',
  props: {
    id: {
      required: false,
      default: 1,
    },
    repeatable: {
      required: false,
      default: 0,
    },
    orderNumber: {
      required: false,
      default: 0,
    },
    title: {
      required: true,
    },
    subTitle: {
      required: true,
    },
    bottomTitle: {
      required: true,
    },
    links: {
      required: true,
    },
    time: {
      required: true,
    },
    image: {
      required: true,
    },
    bottomClass: {
      required: true,
    },
    processWidth: {
      required: true,
    },
    processClass: {
      default: '',
      required: false,
    },
    iconClass: {
      required: true,
    },
    hasPresent: {
      required: true,
    },
    rate: {
      required: false,
    },
    gift: {
      required: false,
    },
    isAssignmentEndDateExpired: {
      required: false,
    },
    isWatchableAgain: {
      required: false,
    }
  }
}

</script>
<style scoped>
.cursor-pointer {
  cursor: pointer;
}

.assignment-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.order {
  font-size: 13px;
  font-weight: bold;
}
</style>
